import React from 'react';
import VideoOrImage from '@vcc-www/video-or-image';
import { ExtendCSS, useTheme } from 'vcc-ui';
import {
  useSpringCarousel,
  SpringCarouselPane,
} from '@vcc-www/spring-carousel';
import SlidingPaginationIndicator from '@vcc-www/sliding-pagination-indicator';
import { Track } from '@volvo-cars/tracking';
import type { Sources } from '@vcc-www/utils/getMediaSources';
import type { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import styles from './Carousel.module.css';

export type MediaItemsProps = {
  body?: string;
  imageSources: Sources;
  videoSourcesMp4?: Sources;
  alt?: string;
};

type CarouselProps = {
  mediaItems: Array<MediaItemsProps>;
  aspectRatio: AspectRatio;
  isOverlayOpen: boolean;
  shouldAutoplay: boolean;
  openOverlayLabel: string;
  setHasInteracted: () => void;
  setIsOverlayOpen: (isOverlayOpen: boolean) => void;
  disableOverlay?: boolean;
  imageDensity?: number;
};

const Carousel = ({
  mediaItems,
  imageDensity,
  aspectRatio,
  setHasInteracted,
  isOverlayOpen,
  shouldAutoplay,
  disableOverlay,
  setIsOverlayOpen,
  openOverlayLabel,
}: CarouselProps) => {
  const { current, setCurrent } = useSpringCarousel();
  const { direction } = useTheme();

  return (
    <div
      className={styles.container}
      style={
        {
          '--expand-icon': `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDFIMTdNMTcgMVY3TTE3IDFMMTEgNyIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNyAxN0wxIDE3TTEgMTdMMSAxMU0xIDE3TDcgMTEiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==')`,
        } as React.CSSProperties
      }
    >
      <SpringCarouselPane
        itemSpacing={{
          default: 24,
          onlyS: 8,
        }}
      >
        {mediaItems.map(({ alt, imageSources, videoSourcesMp4 }, i) => {
          const isCurrent = current - 1 === i;

          return (
            <div key={i} className={styles.assetWrapper}>
              {!disableOverlay && (
                <Track eventLabel="open overlay" eventAction="image|click">
                  <button
                    type="button"
                    className={styles.openOverlayButton}
                    onClick={() => {
                      setIsOverlayOpen(true);
                    }}
                    aria-label={openOverlayLabel}
                    aria-current={isCurrent}
                    disabled={!isCurrent}
                    data-color-mode="light"
                  />
                </Track>
              )}
              <VideoOrImage
                aspectRatio={aspectRatio}
                imageSources={imageSources}
                videoSourcesMp4={videoSourcesMp4}
                altText={alt}
                shouldAutoplay={!isOverlayOpen && shouldAutoplay && isCurrent}
                playPauseButtonTabIndex={isCurrent ? 0 : -1}
                shouldPause={!isCurrent || isOverlayOpen}
                imageDensity={imageDensity}
                sizes={{ default: '100vw', fromL: '70vw' }}
              />
            </div>
          );
        })}
      </SpringCarouselPane>
      {mediaItems?.length && (
        <SlidingPaginationIndicator
          numberOfItems={mediaItems.length}
          currentIndex={current - 1}
          extend={progressIndicatorCSS}
          onClick={(i) => {
            setCurrent(i + 1);
            setHasInteracted();
          }}
          isRtlIndicator={direction === 'rtl'}
        />
      )}
    </div>
  );
};

const progressIndicatorCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  margin: `${baselineGrid}px auto 0`,
});

export default Carousel;
