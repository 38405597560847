var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"eORYHkYyGHQ1FRg4JYXYk"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/static/storefront";

import { init, replayIntegration } from '@sentry/nextjs';
import { commonSentryConfig } from '@utils/sentry.config';

init({
  ...commonSentryConfig('Client Side'),
  // Proportion of traces captured (1.0 = 100%). Reduce this number in future
  tracesSampleRate: 1.0,

  // Replay may only be enabled for the client-side
  integrations: [replayIntegration()],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
