import React from 'react';
import { Track } from '@volvo-cars/tracking';
import { IconButton } from '@volvo-cars/react-icons';
import styles from './CarouselArrow.module.css';
import { useSpringCarousel } from '@vcc-www/spring-carousel';

type CarouselArrowProps = {
  direction: 'left' | 'right';
  nextButtonLabel: string;
  previousButtonLabel: string;
  setHasInteracted: () => void;
};

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  direction,
  nextButtonLabel,
  previousButtonLabel,
  setHasInteracted,
}) => {
  const { current, total, prev, next } = useSpringCarousel();

  if (total === 1) return null;

  const trackLabel = direction === 'left' ? 'previous' : 'next';

  return (
    <Track
      eventLabel={direction === 'left' ? 'previous' : 'next'}
      eventAction={`arrow|${trackLabel}`}
    >
      <IconButton
        icon={direction === 'left' ? 'chevron-back' : 'chevron-forward'}
        data-direction={direction}
        className={`until-md:hidden ${styles.carouselArrow}`}
        onClick={() => {
          direction === 'left' ? prev() : next();
          setHasInteracted();
        }}
        aria-label={
          direction === 'left' ? previousButtonLabel : nextButtonLabel
        }
        variant="filled"
        data-color-mode="dark"
        disabled={direction === 'left' ? current === 1 : current === total}
      />
    </Track>
  );
};

export default CarouselArrow;
