import { LeadsApp } from '@vcc-package/embeddable-forms';

import { RetailerPickerType } from '../../../api';
import { defaultDealerPickerByAddress, urls } from '../../../constants';
import type { LeadsContextProperties } from '../../../src/context';
import type { ABTest } from '../types';

export const hideRetailerMap: ABTest = (context: LeadsContextProperties) => {
  if (
    context.features.HideRetailerMap &&
    context.appId === LeadsApp.QuoteRequest &&
    !context.isEmbeddableForms &&
    context.marketConfig.purposeConfigs &&
    context.marketConfig.purposeConfigs.OFFER_REQUEST
  ) {
    console.log('Running experiment: hideRetailerMap');

    context.marketConfig.purposeConfigs.OFFER_REQUEST.retailer = null;
    context.marketConfig.purposeConfigs.OFFER_REQUEST.retailerPicker = null;
    context.marketConfig.purposeConfigs.OFFER_REQUEST.retailerPickerType =
      RetailerPickerType.BY_ADDRESS;

    if (context.formConfig) {
      context.formConfig.dealerPicker =
        context.formConfig.dealerPicker ?? defaultDealerPickerByAddress;
    }

    if (!context.formConfig?.order?.includes('dealerpicker')) {
      context.formConfig?.order.unshift('dealerpicker');
    }

    const shouldNavigateToForm =
      context.navigation.route === urls[LeadsApp.QuoteRequest].retailer &&
      context.navigation.query.pno;

    if (shouldNavigateToForm) {
      context.navigation.goTo(urls[LeadsApp.QuoteRequest].form, {
        fullNavigation: true,
      });
    }
  }

  return context;
};
