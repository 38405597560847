'use client';

import { FulfillmentInformation } from '@components/fulfillment-information';
import { PAGE_NAMING } from '@config';
import { useModal } from '@contexts/global-modal/global-modal.provider';
import type { RegionCode, SiteSlug } from '@volvo-cars/market-sites';
import { getRetailerNavigationUrl } from 'src/markets/helpers';
import { PageName } from 'src/types/page-search-params';
import AccessoryRequestModal from './accessory-request-modal/accessory-request-modal';
import { useTracker } from '@volvo-cars/tracking';

export type AccessoryRequestFormRolloutSetting = {
  campaignCode: string | undefined;
  clientId: string | undefined;
  shouldEnableAccessoryRequestForm: boolean | undefined;
};

type RetailerCTAProps = {
  accessoryRequestFormRolloutSettings?: AccessoryRequestFormRolloutSetting;
  accessoryRequestFormCtaTextContent?: string;
  accessoryTitle?: string;
  fulfillmentInfo?: string;
  page?: PageName;
  siteSlug: SiteSlug;
  regionCode?: RegionCode;
  retailerCtaTextContent: string;
  overrideCtaStyles?: {
    container?: string;
    retailerCta?: string;
    accessoryRequestCta?: string;
  };
  productHandle?: string;
};

export function RetailerCta({
  accessoryRequestFormRolloutSettings,
  accessoryRequestFormCtaTextContent,
  accessoryTitle,
  fulfillmentInfo,
  page,
  siteSlug,
  retailerCtaTextContent,
  overrideCtaStyles,
  productHandle,
}: RetailerCTAProps) {
  const tracker = useTracker();
  const { openModal } = useModal();

  const { campaignCode, clientId, shouldEnableAccessoryRequestForm } =
    accessoryRequestFormRolloutSettings || {};

  function handleRetailerCtaClick() {
    const navigationUrl = getRetailerNavigationUrl(siteSlug, productHandle);
    window.open(navigationUrl);

    tracker.customEvent({
      eventAction: 'button|click',
      eventLabel: 'Find a dealer',
      eventCategory: `${page === PAGE_NAMING.CampaignPage ? `Campaign` : 'Purchase'}`,
    });
  }

  function handleAccessoryRequestFormCtaClick() {
    openModal({
      modalId: 'accessory-request-form',
      children: (
        <AccessoryRequestModal
          accessoryTitle={accessoryTitle}
          formRolloutSettings={{
            campaignCode: campaignCode,
            clientId: clientId,
          }}
        />
      ),
    });

    tracker.customEvent({
      eventAction: 'button|click',
      eventLabel: 'Contact a volvo specialist',
      eventCategory: 'Purchase',
    });
  }

  return (
    <div
      className={`${overrideCtaStyles?.container ? overrideCtaStyles.container : 'flex-col'}`}
    >
      <button
        aria-label="buy-from-partners-button"
        className={`${overrideCtaStyles?.retailerCta ? overrideCtaStyles.retailerCta : 'button-filled'}`}
        data-color="accent"
        onClick={handleRetailerCtaClick}
        type="button"
      >
        {retailerCtaTextContent}
      </button>
      {shouldEnableAccessoryRequestForm && (
        <button
          aria-label="buy-from-partners-button"
          className={`${overrideCtaStyles?.accessoryRequestCta ? overrideCtaStyles.accessoryRequestCta : 'button-outlined mt-16 mb-8'}`}
          data-color="accent"
          onClick={handleAccessoryRequestFormCtaClick}
          type="button"
        >
          {accessoryRequestFormCtaTextContent}
        </button>
      )}
      {fulfillmentInfo && (
        <FulfillmentInformation
          fulfillmentIcon={{
            type: 'office-retailer-40',
            alt: 'Office retailer icon',
          }}
          fulfillmentInfo={fulfillmentInfo}
        />
      )}
    </div>
  );
}
