import { BASE_URL } from '@config';
import { SiteSlug } from '@volvo-cars/market-sites';

export type MarketConfig = {
  linkToRetailer: {
    link: string;
    containToProducts?: string[];
  };
  retailerTextContent: string;
  excludedProductTypes: string[];
  enablePriceForProducts: string[];
};

type MarketsCollection = { [key: string]: MarketConfig };

export const defaultMarketConfig = (siteSlug: string): MarketConfig => ({
  linkToRetailer: {
    link: `${BASE_URL}/${siteSlug}/dealers`,
  },
  retailerTextContent: 'App.CTA.retailer',
  excludedProductTypes: [''],
  enablePriceForProducts: ['performance-mode'],
});

const homeChargingProducts = [
  'wallbox-selected-by-volvocars-integrated-cable',
  'wallbox-selected-by-volvocars-socket-energy-meter-mid',
  'real-time-energy-meter',
];

const volvoWallboxes = [
  'volvo-wallbox-with-cable',
  'volvo-wallbox-with-socket',
  'volvo-wallbox-with-socket-mid',
];

const markets: MarketsCollection = {
  se: {
    ...defaultMarketConfig('se'),
    linkToRetailer: {
      link: 'https://kgkladdbarafordon.se/volvo/',
      containToProducts: [...homeChargingProducts, ...volvoWallboxes],
    },
    retailerTextContent: 'App.CTA.inquiry',
    excludedProductTypes: ['insurance'],
  },
  nl: {
    ...defaultMarketConfig('nl'),
    linkToRetailer: {
      link: 'https://ccforms.fillout.com/volvo-laadpaal-aanvraag',
      containToProducts: homeChargingProducts,
    },
  },
  // Add other markets here
};

export function getMarketConfigs(market: SiteSlug): MarketConfig {
  return markets[market] || defaultMarketConfig(market);
}
