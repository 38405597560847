'use client';

import sfInfoBanner from '@contentstack/content-types/info-banner/info-banner.props';
import { Track } from '@volvo-cars/tracking';
import Link from 'next/link';

export function InfoBanner({
  infoBannerTitle,
  infoBannerText,
  infoBannerLink,
}: sfInfoBanner) {
  return (
    <div className="container-lg p-8">
      <div className="p-16 flex-row flex-wrap gap-16 border-l-2 border-secondary bg-secondary">
        <h3 className="font-medium">{infoBannerTitle}</h3>
        <p>{infoBannerText}</p>
        {infoBannerLink && (
          <Track
            event="custom_event"
            eventAction="link|click"
            eventLabel={`${infoBannerLink.title}|${infoBannerLink.url}`}
            customData={{
              eventCategory: 'sfInfoBanner',
            }}
          >
            <Link href={infoBannerLink.url}>{infoBannerLink.title}</Link>
          </Track>
        )}
      </div>
    </div>
  );
}
